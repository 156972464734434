import { useState } from 'react';
import { m } from 'framer-motion';
import {
  Button,
  Container,
  Typography,
  Stack,
  CardMedia,
  Box,
  Grid,
  Modal,
  styled,
  alpha,
  useTheme,
} from '@mui/material';
import { PATH_PAGE } from 'routes/paths';
import { MotionContainer, varFade, MotionViewport } from 'components/animate';
import { useLocales } from 'locales';
import Logo from 'components/Logo';
import Image from 'components/Image';
import { DialogAnimate } from 'components/animate';
import useResponsive from 'hooks/useResponsive';

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    minHeight: '50vh',
    top: 30,
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
    },
  })
);

export default function HomeHero() {
  const { currentLang, translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const styleModal = {
    position: 'absolute',
    top: isDesktop ? '50%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isDesktop ? '50vw' : '100vw',
    height: isDesktop ? '60vw' : '100%',
    bgcolor: 'background.default',
    boxShadow: 24,
  };

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <MotionContainer>
      {isDesktop ? (
        <video width="100%" minHeight="100vh" controls autoPlay loop muted>
          <source src="/assets/hero.mp4" type="video/mp4" />
        </video>
      ) : (
        <CardMedia
          component="img"
          image="/assets/slide.png"
          sx={{
            width: 'auto',
            height: '100vh',
          }}
        />
      )}
      <RootStyle>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
            }}
          >
            <Logo />
          </Box>
          <ContentStyle>
            <m.div variants={varFade().inRight}>
              <Box
                sx={{
                  backgroundColor: alpha(theme.palette.common.black, 0.5),
                  borderRadius: 1,
                  p: 4,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: 'common.white',
                    textTransform: 'uppercase',
                  }}
                >
                  {translate('welcometo')}
                </Typography>
                <Typography
                  variant="h1"
                  sx={{ color: 'common.white', textTransform: 'uppercase' }}
                >
                  {translate('brand')}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: 'common.white', textTransform: 'uppercase' }}
                >
                  {translate('baseline')}
                </Typography>
              </Box>
            </m.div>
            <m.div variants={varFade().in}>
              <Stack
                spacing={1.5}
                justifyContent={{ md: 'center' }}
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ my: 2 }}
              >
                <Stack alignItems="center" spacing={2}>
                  <Button
                    color="inherit"
                    variant="outlined"
                    rel="noopener"
                    href={PATH_PAGE.menu}
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#bdb76b',
                      color: 'common.black',
                      px: 5,
                      py: 1,
                      width: 220,
                      fontSize: { xs: 18, md: 24 },
                      '&:hover': {
                        backgroundColor: '#767132',
                      },
                    }}
                  >
                    {translate('menu')}
                  </Button>
                </Stack>
                <Stack alignItems="center" spacing={2}>
                  <Button
                    color="inherit"
                    variant="outlined"
                    rel="noopener"
                    onClick={handleOpen}
                    fullWidth
                    sx={{
                      textTransform: 'uppercase',
                      backgroundColor: '#bdb76b',
                      color: 'common.black',
                      px: 5,
                      py: 1,
                      width: 220,
                      fontSize: { xs: 18, md: 24 },
                      '&:hover': {
                        backgroundColor: '#767132',
                      },
                    }}
                  >
                    {translate('reservation')}
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ marginTop: '10%', minHeight: '10%' }}
                  >
                    <Box sx={styleModal}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button onClick={handleClose} sx={{ color: 'white' }}>
                          X
                        </Button>
                      </Stack>
                      <iframe
                        title="Réservation"
                        src="/reservation.html"
                        width="100%"
                        height="100%"
                        frameborder="0"
                      ></iframe>
                    </Box>
                  </Modal>
                </Stack>
              </Stack>
            </m.div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </MotionContainer>
  );
}
